import React, { Component } from "react";
import styled from "@emotion/styled";
import criteria from "../../../../../shared/resources/_criteria.json";
import ratingsMap from "../../../../../shared/resources/_rating.json";
import Checkmark from "./Checkmark";
import Icon from "./Icon";

import { FormattedMessage, injectIntl } from "react-intl";

const HeaderStyles = styled.header`
  display: ${(props) => (props.hasScore ? "block" : "flex")};
  align-items: flex-start;
  margin-top: ${(props) => (props.hasScore ? "0" : "9px")};
`;

const RatingsDescriptionStyles = styled.p`
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    margin: 5px 0 10px;
  }
`;

const CriteriaContainerStyles = styled.div`
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-top: 6px solid #000;
  border-radius: 2px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  padding: 14px;
  margin-bottom: 65px;
  @media screen and (max-width: 600px) {
    margin-bottom: 35px;
  }
`;

const CriteriaStyles = styled.div`
  font-size: 15px;
  letter-spacing: 0;
  line-height: 23px;
  display: flex;
  margin-bottom: 32px;
  align-items: center;
`;

const CriteriaText = styled.span`
  margin-right: 5px;
`;

const CriteraNoteStyles = styled.p`
  color: rgba(0, 0, 0, 0.4);
  font-family: Sora;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;

  & a {
    color: var(--primary-blue);
    font-family: Sora;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 35px;
  }
`;

const Points = styled.span`
  display: inline-block;
  height: 22px;
  min-width: 22px;

  border-radius: 11px;
  border: none;
  background-color: rgba(0, 0, 0, 0.08);
  color: #000;
  font-size: 11px;
  line-height: 22px;
  text-align: center;
`;

function PointsDisplay({ points, hasLabel }) {
  return (
    <Points>
      &nbsp;&nbsp;{`${points} ${hasLabel ? " points " : ""}`}&nbsp;
    </Points>
  );
}

const ScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0 5px;
  flex-wrap: ${(props) => (props.hasCriteria ? "wrap" : "nowrap")};
  margin-bottom: ${(props) => (props.hasCriteria ? "0" : "15px")};
  flex-direction: column;
  gap: 10px;
`;

const Score = styled.div`
  font-size: 26px;
  font-weight: 300;
  letter-spacing: 0;
  height: 32px;
  line-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
`;

const CurrentScore = styled.span`
  font-weight: 600;
`;

class RatingView extends Component {
  render() {
    const { intl, label } = this.props;
    const hasCriteria = !(
      label.rank === "TK" ||
      label.rank === "S" ||
      label.rank === "P"
    );
    //console.log({ label });

    let key = "rating." + label.rank + ".description";

    let ratingInfo = ratingsMap[label.rank];
    let criteriaMap = {};

    // backward compatibility
    if (label.criteria && hasCriteria) {
      Object.keys(label.criteria).forEach((key) => {
        const topic = label.criteria[key];
        criteriaMap[topic.title] = topic.body;
      });

      if (label.rank === "T") {
        //A
        if (label.score >= ratingsMap.A.minimum) {
          ratingInfo = ratingsMap.A;
          key = "rating.A.description";
        } else if (label.score >= ratingsMap.B.minimum) {
          //B
          ratingInfo = ratingsMap.B;
          key = "rating.B.description";
        } else {
          //C
          ratingInfo = ratingsMap.C;
          key = "rating.C.description";
        }
      } else if (label.rank === "N") {
        if (label.score >= ratingsMap.D.minimum) {
          //D
          ratingInfo = ratingsMap.D;
          key = "rating.D.description";
        } else {
          //F
          ratingInfo = ratingsMap.F;
          key = "rating.F.description";
        }
      }
    }

    return (
      <aside>
        <HeaderStyles hasScore={hasCriteria}>
          <ScoreWrapper hasCriteria={hasCriteria}>
            <Icon rating={label.rank}>
              {hasCriteria ? (
                <Score>
                  <CurrentScore>{label.score}</CurrentScore>
                  <span> / 100</span>
                </Score>
              ) : null}
            </Icon>
            <RatingsDescriptionStyles hasScore={hasCriteria}>
              <FormattedMessage
                id={key}
                defaultMessage={ratingInfo.description}
                values={{
                  strong: (...chunks) => <strong>{chunks}</strong>,
                }}
              />
            </RatingsDescriptionStyles>
          </ScoreWrapper>
        </HeaderStyles>
        {hasCriteria ? (
          <>
            <CriteriaContainerStyles>
              {Object.keys(criteria).map((key) => {
                if (key !== "rating") {
                  const topic = criteriaMap[key];

                  if (topic) {
                    return (
                      <CriteriaStyles key={`criteria_${key}`}>
                        <Checkmark value={topic} />
                        <div>
                          <CriteriaText>
                            <FormattedMessage
                              id={`criteria.${key}.title`}
                              defaultMessage={criteria[key].title}
                            />
                          </CriteriaText>
                          <PointsDisplay
                            points={criteria[key].weight}
                            hasLabel={key === "falseContent"}
                          />
                        </div>
                      </CriteriaStyles>
                    );
                  }
                }
                return null;
              })}
            </CriteriaContainerStyles>

            <CriteraNoteStyles>
              <FormattedMessage
                id="msg.listImportance"
                defaultMessage={"Criteria are listed in order of importance"}
              />
              .{" "}
              <a
                href={intl.formatMessage({
                  id: "moreinfolink",
                  defaultMessage:
                    "https://www.newsguardtech.com/ratings/rating-process-criteria/",
                })}
                target={"_blank"}
                rel="noreferrer"
              >
                <FormattedMessage
                  id="msg.moreInfo"
                  defaultMessage={"Learn More"}
                />
                .
              </a>
            </CriteraNoteStyles>
          </>
        ) : null}
      </aside>
    );
  }
}

export default injectIntl(RatingView);
