import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LabelView from "./_components/LabelView";
import TagManager from "react-gtm-module";

// import './App.css';
import { ExtensionContext } from "./service/ExtensionContext";
import LabelExtensionView from "./_components/LabelExtensionView";
import LabelEmbeddedView from "./_components/LabelEmbeddedView";
import LabelLayout from "./_layout/LabelLayout";

class App extends Component {
  constructor(props) {
    super(props);

    // check whether the extension is installed and active
    // using Context
    // ---------------------------------------------------
    this.state = {
      appConfig: {},
      mounted: false,
    };
  }

  getParameterByName = (name) => {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  componentDidMount = async () => {
    document.body.oncopy = function (e) {
      e.preventDefault();
      return false;
    };
    document.body.onselectstart = function (e) {
      e.preventDefault();
      return false;
    };
    document.body.onmousedown = function (e) {
      e.preventDefault();
      return false;
    };

    try {
      Object.defineProperty(document, "referrer", {
        get: function () {
          return "my new referrer";
        },
      });
    } catch (e) {}

    let gtmId = "GTM-TLQZ88R";
    if (typeof window._gtmId != "undefined" && window._gtmId) {
      gtmId = window._gtmId;
    }

    const tagManagerArgs = {
      gtmId: gtmId,
      events: {
        NutritionView: "Nutrition View",
      },
    };

    await TagManager.initialize(tagManagerArgs);

    window.addEventListener(
      "receive_app_config",
      (event) => {
        console.log("receive_app_config -- custom event");
        let appConfig = event.detail;
        if (appConfig) {
          appConfig.show_dialogs = true;
        }
        console.log(appConfig);
        this.setState({
          appConfig: appConfig,
        });
      },
      false
    );

    window.self.dispatchEvent(
      new CustomEvent("get_newsguard_extension", { detail: {} })
    );

    // deprecated
    window.addEventListener("message", (event) => {
      if (event.origin === document.location.origin) {
        const message = event.data;
        if (message.action === "receive_app_config") {
          let appConfig = {
            show_dialogs: true,
          };
          if (message.data) {
            console.log(message.data);
            appConfig = Object.assign(appConfig, message.data);
          }
          console.log(appConfig);
          this.setState({
            appConfig: appConfig,
          });
        }
      }
    });

    // deprecated
    window.self.postMessage(
      { action: "get_newsguard_extension" },
      document.location.origin
    );
    this.setState({ mounted: true });
  };

  render() {
    return (
      <ExtensionContext.Provider value={this.state.appConfig}>
        {this.state.mounted ? (
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LabelLayout component={LabelView} />} />
              <Route
                path="/:uuid"
                element={<LabelLayout component={LabelEmbeddedView} />}
              />

              <Route
                path="/v1/:uuid"
                element={<LabelLayout component={LabelView} />}
              />
              <Route
                path="/v2/:uuid"
                element={<LabelLayout component={LabelView} />}
              />
              <Route
                path="/v3/:uuid"
                element={<LabelLayout component={LabelEmbeddedView} />}
              />
              <Route
                path="/label/:host"
                element={<LabelLayout component={LabelExtensionView} />}
              />
              <Route
                path="/label/:env/:host"
                element={<LabelLayout component={LabelExtensionView} />}
              />
              <Route
                path="/:env/:uuid"
                element={<LabelLayout component={LabelView} />}
              />
            </Routes>
          </BrowserRouter>
        ) : (
          ""
        )}
      </ExtensionContext.Provider>
    );
  }
}

export default App;
