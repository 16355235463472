import App from "./App";
import React from "react";
import ReactDOM from "react-dom";

import getLocaleMessages from "../../../../shared/resources/_localeMessages";
import { IntlProvider } from "react-intl";

const locale = navigator.language;
//const locale = "it";

ReactDOM.render(
  <IntlProvider locale={locale} messages={getLocaleMessages(locale)}>
    <App />
  </IntlProvider>,
  document.getElementById("root")
);
