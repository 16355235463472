import React from "react";
import styled from "@emotion/styled";

const CheckmarkContainer = styled.div`
  height: 20px;
  width: 20px;
  margin-right: ${(props) => (props.margin ? 0 : "14px")};
`;

const CheckmarkStyles = styled.svg`
  height: ${(props) => (props.isNA ? "26px" : "20px")};
  width: ${(props) => (props.isNA ? "26px" : "20px")};
  margin-top: ${(props) => (props.isNA ? "-3px" : "0")};
  margin-right: ${(props) => (props.margin ? 0 : "14px")};
`;

export default function Checkmark(props) {
  let checkmark = <i className={`fas`} />;
  if (props.value) {
    if (props.value === "No") {
      checkmark = (
        <CheckmarkContainer title="Fail" margin={props.margin}>
          <CheckmarkStyles
            color="#FF4444"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="times"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
            margin={props.margin}
          >
            <path
              fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
            ></path>
          </CheckmarkStyles>
        </CheckmarkContainer>
      );
    } else if (props.value === "Yes") {
      checkmark = (
        <CheckmarkContainer title="Pass" margin={props.margin}>
          <CheckmarkStyles
            color="#00CC88"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="check"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            margin={props.margin}
          >
            <path
              fill="currentColor"
              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
            ></path>
          </CheckmarkStyles>
        </CheckmarkContainer>
      );
    } else {
      checkmark = (
        <CheckmarkContainer title="N/A">
          <CheckmarkStyles
            color="#000000"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="check"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="26px"
            height="26px"
            viewBox="0 0 26 26"
            isNA={true}
          >
            <path
              fill="currentColor"
              d="M6.44588004,7 L8.84643476,15.0576906 L8.84643476,7 L10.6614122,7 L10.6614122,19 L8.71122154,19 L6.21497746,11.3153986 L6.21497746,19 L4.4,19 L4.4,7 L6.44588004,7 Z M20.3822027,7.01025995 L22.4,18.9998964 L20.4986941,18.9998964 L20.0660118,16.2431816 L18.1740668,16.2431816 L17.7320236,18.9998964 L15.8411187,18.9998964 L17.8859586,7.01025995 L20.3822027,7.01025995 Z M15.5265919,7.01025995 L13.481752,18.9998964 L11.8997573,18.9998964 L13.9445972,7.01025995 L15.5265919,7.01025995 Z M19.1257599,9.75661111 L18.4528141,14.4824078 L19.7976655,14.4824078 L19.1257599,9.75661111 Z"
            ></path>
          </CheckmarkStyles>
        </CheckmarkContainer>
      );
    }
  }

  return checkmark;
}
