class networkService {
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  formatHeader = (headers) => {
    let configHeaders = this.headers;
    if (headers) {
      configHeaders = Object.assign({}, configHeaders, headers);
    }
    return configHeaders;
  };

  get = (url, headers = {}) => {
    return fetch(url, {
      headers: this.formatHeader(headers),
    }).then((response) => this.handleResponse(response));
  };

  delete = (url, headers = {}) => {
    let that = this;
    return fetch(url, {
      method: "DELETE",
      headers: this.formatHeader(headers),
    }).then((response) => this.handleResponse(response));
  };

  patch = (url, data, headers = {}) => {
    let that = this;
    return fetch(url, {
      method: "PATCH",
      headers: this.formatHeader(headers),
      body: JSON.stringify(data),
    }).then((response) => this.handleResponse(response));
  };

  post = (url, data, headers = {}) => {
    let that = this;
    return fetch(url, {
      method: "POST",
      headers: this.formatHeader(headers),
      body: JSON.stringify(data),
    }).then((response) => this.handleResponse(response));
  };

  handleResponse = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((value) => {
        return Promise.reject({
          status: response.status,
          response: value,
        });
      });
    }
  };
}

export default new networkService();
