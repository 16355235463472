import React from "react";
import { useParams } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import Header from "../_components/Header";
import Footer from "../_components/Footer";

const GlobalStyle = createGlobalStyle`
@font-face {
	font-family: 'Sora';
	src: url('/fonts/Sora-VariableFont_wght.woff2') format("truetype supports variations"),
       url('/fonts/Sora-VariableFont_wght.woff2') format("truetype-variations"),
       url('/fonts/Sora-VariableFont_wght.ttf') format("truetype supports variations"),
       url('/fonts/Sora-VariableFont_wght.ttf') format("truetype-variations"); 
  font-weight: 1 999;
}


:root {
  --primary-blue: #3355dd;
  --primary-black: #000;
  --primary-white: #fff;
  --primary-gray: rgba(0, 0, 0, 0.06);
  --primary-gray-disabled: rgba(0, 0, 0, 0.4);
  -webkit-font-smoothing: antialiased;
}

html, body {
  height: 100%;
  
}



body {
  font-family: 'Sora', sans-serif;
  font-size: 16px;
  color: #000;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  margin: 0;

  &.has-overlay {
    /* position:fixed; */
    overflow:hidden;
  }
}

h1, h2, h3, h4 {
  margin:0;
  font-weight: 500;
}

h1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 51px;
  margin-bottom: 50px;
}


th {
  font-weight: 500;
}

.main-container {
  max-width: 1160px;
  margin: 120px auto 0;
  min-height: 500px;
  /* min-width: 785px; */
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 0 20px;

  @media screen and (max-width: 600px) {
    min-width: 300px;
    padding: 0 20px;
    /* margin-top: 35px; */
  }
  
  &--search {
    isolation: isolate;
    margin-top: 140px;
    min-width: auto;
    max-width: 1215px;
    @media screen and (max-width: 600px) {
      margin-top: 72px;
      padding: 0 20px;
    }  
  }
  &--label, &--settings {
    margin-top: 190px;
    /* padding: 0; */
    @media screen and (max-width: 600px) {
    margin-top: 120px;
    }
  }
  &--home {
    margin-top: 20px;
    min-width: auto;
    @media screen and (max-width: 600px) {
      padding: 0 20px;
    }  
    @media screen and (max-width: 900px) {
      margin-top: 20px;

    }  
  }
 
}




// ----- optanon cookie banner -----

.optanon-alert-box-wrapper {
  background-color: #3355dd !important;
  width: calc(100% - (calc(1.25rem*2)));
  left: 1.125rem;
  bottom: 1.25rem !important;
  padding: 20px 0;
  border-radius: 2px;

  @media screen and (max-width: 600px) {
    bottom: 0 !important;
  }
}

.optanon-alert-box-wrapper * {
  font-family: "Sora", sans-serif;
}

.optanon-alert-box-bg {
  display: flex;
  align-items: center;
  min-width: 300px;
  padding: 0 25px !important;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.optanon-alert-box-logo,
.optanon-alert-box-wrapper .optanon-alert-box-bottom-top {
  display: none;
}
.optanon-alert-box-wrapper .optanon-alert-box-body {
  margin-left: 0 !important;
  padding-top: 0 !important;
  margin-right: 0 !important;
  padding-right: 40px;
  @media screen and (max-width: 600px) {
    padding-right: 0;
    margin-bottom: 20px;
  }
}

.optanon-alert-box-wrapper .optanon-alert-box-bg p {
  font-family: "Sora", sans-serif !important;
  font-size: 12px !important;
  line-height: 18px !important;
  max-width: 800px;
  @media screen and (max-width: 600px) {
    font-size: 10px !important;
  }
}

// optanon button css

.accept-cookie-container {
  background-color: #3355dd !important;
  border: 0;
  padding: calc(1.25rem / 2);
  max-height: 50px;
}

.optanon-alert-box-button {
  float: none !important;
  margin: 0 !important;
}

.optanon-alert-box-button button {
  background-color: #3355dd !important;
  border: 1px solid #fff !important;
  border-radius: 2px;
  color: #fff;
  font-size: 16px !important;
  line-height: 21px !important;
  padding: 9px 19px !important;
  font-weight: 600 !important;
  margin: 0;
  text-decoration: none;
}

.optanon-alert-box-bg .optanon-alert-box-button-container {
  position: initial !important;
  right: auto !important;
  top: auto !important;
  margin-top: 0 !important;
  display: flex;
  flex-direction: row-reverse;
  flex-shrink: 0;
  gap: 30px;
  padding-right: 0 !important;
  margin-left: auto !important;

  @media screen and (max-width: 600px) {
    margin-left: 0 !important;
  }
}

.optanon-alert-box-button-middle {
  border: 0 !important;
  padding: 0 !important;
  font-size: inherit !important;
  font-weight: normal !important;
}
.optanon-alert-box-button-middle button::before {
  display: none;
}

.optanon-alert-box-wrapper a.banner-policy-link {
  color: #fff !important;
}

.optanon-alert-box-button-middle button {
  @media screen and (max-width: 600px) {
    font-size: 10px !important;
  }
}

// ----- end of optanon css

:root {
  --reach-dialog: 1;
}

[data-reach-dialog-overlay] {
  background: hsla(0, 0%, 0%, 0.86);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

[data-reach-dialog-content] {
  width: 50vw;
  margin: 10vh auto;
  background: white;
  padding: 2rem;
  outline: none;
  border-radius: 2px;
}
`;

const DefaultLayout = ({ component: Component, ...rest }) => {
  let params = useParams();
  rest.match = {
    params: params,
  };

  return (
    <>
      <GlobalStyle />
      <Header {...rest} />
      <main role="main" className="main-container">
        <Component {...rest} />
      </main>
      <Footer {...rest} />
    </>
  );
};

export default DefaultLayout;
