import React, { Component } from "react";
import Label from "./Label";

import NutritionService from "../service/NutritionService";

import messages from "../../../../../shared/resources/_localeMessages";
import { IntlProvider } from "react-intl";
import TermsOfService from "./TermsOfService";
import { ExtensionContext } from "../service/ExtensionContext";

class LabelExtensionView extends Component {
  static contextType = ExtensionContext;

  constructor(props) {
    super(props);
    this.state = {
      label: {},
      show: false,
      locale: "en-US",
    };
  }

  onCopy(e) {
    e.preventDefault();
  }

  componentDidMount() {
    let locale = "en-US";
    window.addEventListener(
      "receive_app_config",
      (event) => {
        console.log(this.props);
        NutritionService.getInstance().requestLabelFromExtension(
          this.props.match.params.host
        );
      },
      false
    );

    window.addEventListener("receive_label", (event) => {
      const label = event.detail;
      this.setLabel(label);
    });

    // deprecated
    window.addEventListener("message", (event) => {
      if (event.origin === document.location.origin) {
        const message = event.data;
        if (message.action === "receive_app_config") {
          NutritionService.getInstance().requestLabelFromExtension(
            this.props.match.params.host
          );
        } else if (message.action === "receive_label") {
          const label = message.data;
          this.setLabel(label);
        }
      }
    });

    // if after 5 seconds, request label if we do not have label.
    setTimeout(
      () => {
        if (!this.state.show) {
          window.self.dispatchEvent(
            new CustomEvent("get_newsguard_extension", { detail: {} })
          );
        }
      },
      5000 //  in micro second
    );
  }

  setLabel = (label) => {
    let locale = "en-US";
    if (label?.error) {
      // error happened ....
      // need some message
    } else if (
      // HealthGuard ==> healthGuard page
      // NewsGuard ==> newsguard page
      (this.props.match.params.env !== "health" && !label.healthGuardApp) ||
      (this.props.match.params.env === "health" && label.healthGuardApp)
    ) {
      locale = `${label.locale}`;
      if (locale) {
      } else {
        locale = `${label.language}-${label.country}`;

        if (label.country === "ALL") {
          locale = label.language;
        }
      }

      this.setState({
        label: label,
        show: true,
        locale: locale || "en-US",
      });
    }
  };

  render() {
    const { show, label, locale } = this.state;
    return show ? (
      <ExtensionContext.Consumer>
        {(appConfig) => (
          <IntlProvider
            locale={locale || "en-US"}
            messages={messages(locale || "en-US")}
          >
            <div>
              {label && label.rank ? (
                <Label label={label} />
              ) : (
                <div>No Label</div>
              )}
              <TermsOfService appConfig={appConfig} />
            </div>
          </IntlProvider>
        )}
      </ExtensionContext.Consumer>
    ) : (
      ""
    );
  }
}

export default LabelExtensionView;
