import es_US from "./locales/es_US";
import de_DE from "./locales/de_DE";
import fr_FR from "./locales/fr_FR";
import it_IT from "./locales/it_IT";
import fr_CA from "./locales/fr_CA.json";
import en_CA from "./locales/en_CA.json";

const messages = {
  //"es-US": es_US,
  //"es" : es_US,
  "de-DE": de_DE,
  de: de_DE,
  "fr-FR": fr_FR,
  fr: fr_FR,
  "it-IT": it_IT,
  it: it_IT,
  "fr-CA": fr_CA,
  "en-CA": en_CA,
};

const getLocaleMessages = (locale) => {
  let message = messages[locale];
  try {
    if (message) return message;

    let localeParts = locale.split("-");
    message = messages[localeParts[0]];
  } catch (ex) {}
  return message;
};

export function getLocale() {
  let lang = navigator.language;
  if (messages[lang]) {
    return lang;
  }
  return "en-US";
}

export function getLanguage() {
  const locale = getLocale();
  try {
    const res = locale.split("-");
    if (res.length > 0) {
      return res[0];
    }
  } catch (ex) {}
  return "en";
}

export function getCountry() {
  const locale = getLocale();
  try {
    const res = locale.split("-");
    if (res.length > 1) {
      return res[1];
    }
  } catch (ex) {}
  return "US";
}

export default getLocaleMessages;
