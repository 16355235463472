import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";

import TOSAndPrivacyPolicy from "./TOSAndPrivacyPolicy";

const Footer = styled.footer`
  margin: auto auto 20px 0;

  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  div {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }
`;
const Disclaimer = styled.div`
  text-transform: uppercase;
`;

const LabelFooter = () => {
  return (
    <Footer>
      <TOSAndPrivacyPolicy />
      <div>
        &copy; {new Date().getFullYear()} NewsGuard<sup>&reg;</sup>{" "}
        Technologies, Inc. NewsGuard Technologies, Inc. is solely responsible
        for the content.
      </div>
      <Disclaimer>
        <FormattedMessage
          id="msg.proprietary_information"
          defaultMessage="Proprietary Information"
        />
      </Disclaimer>
    </Footer>
  );
};

export default LabelFooter;
