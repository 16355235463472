import React from "react";
import styled from "@emotion/styled";

const SvgSTyles = styled.svg`
  height: 100%;
  margin-right: 12px;
  align-self: flex-start;
  flex-shrink: 0;

  @media screen and (max-width: 600px) {
    height: 35px;
    margin-right: 20px;
    align-self: center;
  }
`;

const Score = styled.div`
  font-size: 16px;
  font-family: "Sora", sans-serif;
  background: #35d;
  border-radius: 0 4px 4px 0;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0;
  padding: 1px 3px;
  line-height: 1;
  letter-spacing: -0.04rem;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;

  height: 34px;
`;

const InPageBlueSVG = styled.svg`
  height: 100%;
  align-self: flex-start;
  flex-shrink: 0;
`;

export default function Icon({ rating, children }) {
  const WideBlueSVG = (
    <Wrapper>
      <InPageBlueSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 88">
        <g id="Layer_1">
          <path
            d="M66,0V33.09c0,23.09,0,38.36-30.76,53.78l-2.24,1.13-2.25-1.13C0,71.43,0,56.18,0,33.09V0H66ZM10,33.08c0,21.4,0,31.53,23,43.66,23.02-12.13,23.02-22.25,23-43.63V10H10v23.08Zm39.4,54.92c9.86-6.44,16.34-13.55,20.06-21.89,4.54-10.18,4.54-20.77,4.54-33.02v54.91h-24.6Z"
            fill="#35d"
            fillRule="evenodd"
          />
        </g>
      </InPageBlueSVG>
      <Score>{children}</Score>
    </Wrapper>
  );

  const platformSvg = (
    <Wrapper>
      <SvgSTyles
        viewBox="0 0 27 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#999999"
          d="M12,0v6c0,4.2,0,7-5.6,9.8L6,16l-0.4-0.2C0,13,0,10.2,0,6V0H12z M1.8,6c0,3.9,0,5.7,4.2,7.9v0
	c4.2-2.2,4.2-4,4.2-7.9V1.8H1.8V6z M13.5,0H14v16H9.1c4.4-2.9,4.4-6.2,4.4-10V0z"
        />
        <path
          fill="#999999"
          d="M13.6,0H26c0.6,0,1,0.4,1,1v14c0,0.6-0.5,1-1,1H13.6V0z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M21.7,6.5h-2.9V8h1.1v2.9h-1.1v1.5h3.8v-1.5h-0.9V6.5z M20.6,3.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
	s1.3-0.6,1.3-1.3C21.9,3.8,21.3,3.3,20.6,3.3z"
        />
      </SvgSTyles>
    </Wrapper>
  );

  const satireSvg = (
    <Wrapper>
      <SvgSTyles
        viewBox="0 0 28 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12,0v6c0,4.2,0,7-5.6,9.8L6,16l-0.4-0.2C0,13,0,10.2,0,6V0H12z M1.8,6c0,3.9,0,5.7,4.2,7.9v0
        c4.2-2.2,4.2-4,4.2-7.9V1.8H1.8V6z M13.5,0H14v16H9.1c4.4-2.9,4.4-6.2,4.4-10V0z"
          fill="#FF9100"
        />
        <path
          d="M13.5,0H26c0.6,0,1,0.4,1,1v14c0,0.6-0.5,1-1,1H13.5V0z"
          fill="#FF9100"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4,9L22.4,9c-0.4,0.8-1.2,1.3-2,1.3s-1.6-0.5-2-1.3l-1.6,0.8l0,0.1c0.7,1.4,2.1,2.3,3.6,2.3s3-0.9,3.6-2.3
        L22.4,9L22.4,9z M22.7,4.4c-1,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7c1,0,1.7-0.8,1.7-1.7S23.6,4.4,22.7,4.4z M18.3,4.4
        c-1,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7c1,0,1.7-0.8,1.7-1.7S19.3,4.4,18.3,4.4z M22.7,5.6c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5
        c-0.3,0-0.5-0.2-0.5-0.5S22.4,5.6,22.7,5.6z M18.3,5.6c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5
        S18.1,5.6,18.3,5.6z"
          fill="white"
        />
      </SvgSTyles>
    </Wrapper>
  );

  const unratedSvg = (
    <Wrapper>
      <SvgSTyles
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28 16"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 6.02V0H0V6.02C0 10.22 0 12.99 5.59 15.8L6 16L6.41 15.8C12 13 12 10.22 12 6.02ZM6 13.95C1.82 11.75 1.82 9.91 1.82 6.02V1.82H10.18V6.02C10.18 9.91 10.18 11.75 6 13.95ZM26 0H13.5V6.02C13.5 9.77 13.5 13.06 9.04999 16H26C26.55 16 27 15.55 27 15V1C27 0.45 26.55 0 26 0ZM25.8 14.8H12.29C14.7 12.03 14.7 8.99 14.7 6.02V1.2H25.8V14.8ZM17.05 7.08997H23.96V8.90997H17.05V7.08997Z"
          fill="black"
        />
      </SvgSTyles>
    </Wrapper>
  );

  const neutralSvg = (
    <Wrapper>
      <SvgSTyles
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28 16"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0V6.02C12 10.22 12 13 6.41 15.8L6 16L5.59 15.8C0 12.99 0 10.22 0 6.02V0H12ZM1.82 6.02C1.82 9.91 1.82 11.75 6 13.96V13.95C10.18 11.75 10.18 9.91 10.18 6.02V1.82H1.82V6.02ZM13.5 0H14V16H9.05C13.5 13.06 13.5 9.77 13.5 6.02V0Z"
          fill="#FF9100"
        />
        <path
          d="M14 0H26C26.5523 0 27 0.447715 27 1V15C27 15.5523 26.5523 16 26 16H14V0Z"
          fill="#FF9100"
        />
        <path
          d="M23.9545 7.09094V8.90892H17.0455V7.09094H23.9545Z"
          fill="white"
        />
      </SvgSTyles>
    </Wrapper>
  );

  switch (rating) {
    case "S":
      return satireSvg;
    case "P":
      return platformSvg;
    case "T":
    case "A":
    case "B":
    case "C":
    case "N":
    case "D":
    case "F":
      return WideBlueSVG;
    case "TK":
      return unratedSvg;
    default:
      return neutralSvg;
  }
}
