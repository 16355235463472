import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";

const Header = styled.header`
  position: fixed;
  top: 0;
  text-align: center;

  margin: 0 auto;
  width: 100%;
  z-index: 21;
`;

const NavStyling = styled.nav`
  text-align: center;
  margin: 0 auto;
  height: 22px;
  background-color: #fff;
  z-index: 1;
  border-bottom: 1px solid rgba(229, 229, 229, 1);
  padding: 31px 35px 31px 35px;
  display: flex;

  justify-content: space-between;
  align-items: center;

  .partner-logo img {
    width: 185px;
  }

  @media screen and (max-width: 600px) {
    padding-left: 20px;
    height: 60px;

    & .partner-logo {
      z-index: 20;
      & img {
        width: 132px;
      }
    }
  }
`;

const NavItems = styled.ul`
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    background: #fff;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    height: 100vh;
    text-align: left;
    padding: 2rem;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* transition: transform 0.3s ease-in-out; */

    font-size: 18px;
    line-height: 30px;

    &.nav-items {
      padding: 100px 0 0 28px;
      align-items: flex-start;

      > li,
      li li {
        padding-left: 0;
        margin-bottom: 20px;
      }
    }
  }
`;

const MarketingLink = styled.a`
  text-decoration: none;
  color: #3355dd;

  &:hover {
    text-decoration: underline;
  }
`;

function NutritionLabelHeader({ ...rest }) {
  console.log(rest);

  let logoPath = "/assets/icons/svg/NewsGuardLogo_SMALL-Black.svg";
  let link = "https://www.newsguardtech.com/";
  let linkAccount = "https://account.newsguardtech.com/";

  if (rest.match?.params?.env && rest.match.params.env === "health") {
    logoPath = "/assets/icons/svg/HealthGuardLogo_Small.svg";
    link = "https://www.newsguardtech.com/welcome-to-healthguard/";
    linkAccount = link;
  }
  return (
    <>
      <Header>
        <NavStyling>
          <div className="partner-logo">
            <a href="/">
              <img
                src="/assets/icons/svg/NewsGuardLogo_SMALL-Black.svg"
                alt="NewsGuard Logo"
                className="partner-logo"
              />
            </a>
          </div>
          <div>
            <MarketingLink
              href={link}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {rest.match?.params?.env && rest.match.params.env === "health" ? (
                <FormattedMessage
                  id="msg.whatishealthguard"
                  defaultMessage="What is HealthGuard, a service of NewsGuard<sup>{regsymbol}</sup>?"
                  values={{
                    sup: (chunk) => <sup>{chunk}</sup>,
                    regsymbol: <span>&reg;</span>,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="msg.whatisnewsguard"
                  defaultMessage="What is NewsGuard<sup>{regsymbol}</sup>?"
                  values={{
                    sup: (chunk) => <sup>{chunk}</sup>,
                    regsymbol: <span>&reg;</span>,
                  }}
                />
              )}
            </MarketingLink>
          </div>
        </NavStyling>
      </Header>
    </>
  );
}

export default NutritionLabelHeader;
