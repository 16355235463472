import React, { Component } from "react";
import Label from "./Label";

import NutritionService from "../service/NutritionService";

import messages from "../../../../../shared/resources/_localeMessages";
import { IntlProvider } from "react-intl";
import EmptyLabelView from "./EmptyLabelView";
import TermsOfService from "./TermsOfService";
import { ExtensionContext } from "../service/ExtensionContext";

class LabelView extends Component {
  static contextType = ExtensionContext;

  constructor(props) {
    super(props);
    this.state = {
      label: {},
      show: false,
      locale: "en-US",
    };
  }

  onCopy(e) {
    e.preventDefault();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const that = this;
    let locale = "en-US";

    if (this.props.match.params.uuid) {
      that.setState({
        show: false,
      });
      const nutritionService = NutritionService.getInstance();

      nutritionService
        .labelByToken(this.props.match.params.uuid)
        .then((label) => {
          locale = `${label.locale}`;
          if (locale) {
          } else {
            locale = `${label.language}-${label.country}`;

            if (label.country === "ALL") {
              locale = label.language;
            }
          }

          that.setState({
            label: label,
            show: true,
            locale: locale,
          });
        })
        .catch((error) => {
          that.setState({
            show: true,
            locale: locale,
          });
        });
    } else {
      that.setState({
        show: true,
        locale: locale,
      });
    }
  }

  render() {
    return this.state.show ? (
      <IntlProvider
        locale={this.state.locale}
        messages={messages(this.state.locale)}
      >
        <ExtensionContext.Consumer>
          {(appConfig) => (
            <div>
              {this.state.label && this.state.label.rank ? (
                <Label
                  label={this.state.label}
                  appConfig={appConfig}
                  env={this.props.match.params.env}
                />
              ) : (
                <EmptyLabelView
                  appConfig={appConfig}
                  env={this.props.match.params.env}
                />
              )}
              <TermsOfService appConfig={appConfig} />
            </div>
          )}
        </ExtensionContext.Consumer>
      </IntlProvider>
    ) : (
      ""
    );
  }
}

export default LabelView;
