import React, { Component } from "react";
import styled from "@emotion/styled";
import criteria from "../../../../../shared/resources/_criteria";

import {
  FormattedMessage,
  FormattedNumber,
  FormattedRelative,
} from "react-intl";
import {
  BrowserView,
  isBrowser,
  isChrome,
  isEdge,
  isFirefox,
  isMobile,
  isSafari,
  MobileView,
  browserName,
  BrowserTypes,
} from "react-device-detect";
import TagManager from "react-gtm-module";
import cookie from "react-cookies";

const Link = styled.a`
  color: var(--primary-blue);

  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const LinkButton = styled.a`
  background-color: #3355dd;
  color: #fff;
  height: 50px;
  padding: 0 20px 0 11px;
  line-height: 20px;
  font-family: Sora;
  font-size: 14px;
  border-radius: 2px;
  border: none;
  display: flex;
  align-items: center;
  text-decoration: none;

  & :hover {
    text-decoration: underline;
  }
`;

const IconWrapper = styled.div``;

const Heading3 = styled.h3`
  font-family: "Sora";
  font-size: 14px;
  font-weight: 500;
`;

const Icon = styled.div`
  background-image: url(${(props) => props.src});
  width: 22px;
  height: 22px;
  margin-left: 10px;
  margin-right: 10px;
  background-size: 20px 26px;
  background-position-y: -2px;
  background-repeat: no-repeat;
  flex-shrink: 0;
`;

const DownloadWrapper = styled.div`
  width: fit-content;
`;

const browsers = {
  chrome: {
    icon: "/assets/icons/svg/chrome.svg",
    link: "https://bit.ly/2mMUEKj",
  },
  edge: {
    icon: "/assets/icons/svg/edge.svg",
    link: "https://bit.ly/2M3TFlz",
  },
  firefox: {
    icon: "/assets/icons/svg/firefox.svg",
    link: "https://mzl.la/2x4rE5w",
  },
  safari: {
    icon: "/assets/icons/svg/safari.svg",
    link: "https://apple.co/3kPc9pj",
  },
};

class DownloadView extends Component {
  render() {
    const browser = browsers[this.props.browser.toLowerCase()];

    if (browser) {
      return (
        <DownloadWrapper>
          <IconWrapper>
            <LinkButton
              href={browser.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon src={browser.icon} />
              <Heading3>Get NewsGuard</Heading3>
            </LinkButton>
          </IconWrapper>
        </DownloadWrapper>
      );
    } else {
      return null;
    }
  }
}

class EmptyLabelView extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    try {
      const tagManagerArgs = {
        dataLayer: {
          event: "NutritionView",
          view: "EmptyLabel",
          content_type: "EmptyLabel",
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    } catch (e) {}
  }

  render() {
    return (
      <div>
        <p>Thank you for your interest in NewsGuard.</p>

        <p>
          In order to view this NewsGuard Nutrition Label, you must have the
          NewsGuard browser extension installed. You can download the extension
          for{" "}
          <Link
            href={browsers.chrome.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Chrome
          </Link>
          ,{" "}
          <Link
            href={browsers.edge.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge
          </Link>
          ,{" "}
          <Link
            href={browsers.firefox.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Mozilla Firefox
          </Link>
          , or{" "}
          <Link
            href={browsers.safari.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Apple Safari
          </Link>
          .
        </p>
        <DownloadView browser={browserName} />
        <p>
          Once you have the NewsGuard browser extension installed, navigate to
          the website and click the NewsGuard shield icon in your browser's
          navigation bar to see the site's nutrition label. Learn more about{" "}
          <Link
            href="https://www.newsguardtech.com/how-it-works/"
            target="_blank"
            rel="noopener noreferrer"
          >
            how our extension works
          </Link>
          .
        </p>
      </div>
    );
  }
}

export default EmptyLabelView;
