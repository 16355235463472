import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";

import RatingView from "./RatingView";
import Byline from "./Byline";

import { ExtensionContext } from "../service/ExtensionContext";
import TagManager from "react-gtm-module";
import { useSearchParams } from "react-router-dom";

const LabelContainerStyles = styled.div`
  display: grid;
  grid-template-columns: 760px minmax(285px, 1fr);
  grid-template-rows: min-content 3fr;
  grid-column-gap: 46px;

  .scoring {
    grid-column: 2;
    grid-row: 1 / -1;
  }

  .label {
    grid-column: 1;
  }

  @media (max-width: 924px) {
    grid-template-columns: 650px;
    grid-template-rows: auto;
    .scoring {
      grid-column: 1;
      grid-row: 2;
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  h2 {
    margin: 40px 0 20px 0;
  }
`;

const LabelBlurbStyles = styled.div`
  grid-column: 1;
  padding-bottom: 20px;
`;

const LabelTopLineDesecriptionStyles = styled.p`
  font-size: 24px;
  font-weight: 200;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 30px;
  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 30px;
    font-variation-settings: "wght" 100;
    margin-bottom: 10px;
  }
`;

const LabelSectionHeaderStyles = styled.h3`
  font-size: 20px;

  line-height: 30px;
  margin-bottom: 22px;
  margin-top: 10px;
`;

const LabelSectionTextStyles = styled.p`
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 35px;

  & a {
    color: var(--primary-blue) !important; // overriding embedded html styles
    text-decoration: underline !important;
  }
`;

const LabelSectionSourceWrapper = styled.div`
  margin: 0;
  padding: 0;
`;

const LabelSectionSourceTextStyles = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  overflow-wrap: anywhere;
  :last-of-type:not(:only-of-type) {
    margin-bottom: 35px;
  }

  a {
    color: var(--primary-blue);
    text-decoration: none;
    display: inline-block;
    :hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
    word-break: break-all;
    margin-bottom: 15px;

    :last-of-type:not(:only-of-type) {
      margin-bottom: 35px;
    }
  }
`;

const TitleStyle = styled.h1`
  font-size: 40px;
  line-height: 51px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  word-break: break-all;

  @media screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 25px;
  }
`;

const SubHead = styled.p`
  font-size: 16px;
  position: relative;
  top: -50px;

  span {
    font-size: 14px;
    width: 71ch;
    display: inline-block;
    margin-top: 10px;

    @media screen and (max-width: 600px) {
      width: 34ch;
    }
  }

  a {
    color: var(--primary-blue);
  }

  @media screen and (max-width: 600px) {
    top: -30px;
  }
`;

const SourceHeader = () => {
  return (
    <h2>
      <FormattedMessage id={"msg.sources"} defaultMessage={"Sources"} />
    </h2>
  );
};

const Source = ({ sources }) => {
  if (sources && sources.length === 1) {
    return (
      <>
        <SourceHeader />
        {sources[0].sources.map((bullet, index) => (
          <LabelSectionSourceWrapper key={`source_${index}`}>
            <LabelSectionSourceTextStyles
              dangerouslySetInnerHTML={{ __html: bullet }}
            />
          </LabelSectionSourceWrapper>
        ))}
      </>
    );
  } else if (sources) {
    return (
      <>
        <SourceHeader />
        {sources.map((source, index) => {
          return (
            <LabelSectionSourceWrapper key={`source_${index}`}>
              <LabelSectionHeaderStyles>{source.key}</LabelSectionHeaderStyles>
              {source.sources.map((bullet, bulletIndex) => {
                return (
                  <LabelSectionSourceTextStyles
                    key={`source_${index}_${bulletIndex}`}
                    dangerouslySetInnerHTML={{ __html: bullet }}
                  />
                );
              })}
            </LabelSectionSourceWrapper>
          );
        })}
      </>
    );
  }
};

const Writeup = ({ label }) => {
  if (label.writeup) {
    const keys = Object.keys(label.writeup).sort();
    return keys.map((key) => {
      const blurb = label.writeup[key];
      return (
        <>
          <LabelSectionHeaderStyles key={key}>
            {blurb.title}
          </LabelSectionHeaderStyles>
          {blurb.body.map((paragraph, index) => {
            return (
              <LabelSectionTextStyles
                dangerouslySetInnerHTML={{ __html: paragraph }}
                key={`criteria_${key}_${index}`}
              />
            );
          })}
        </>
      );
    });
  }
  return null;
};

const Footnote = ({ footnotes }) => {
  if (footnotes && footnotes.length > 0) {
    return (
      <div>
        <h2>Footnotes</h2>
        {footnotes.map((footnote, index) => {
          return (
            <LabelSectionTextStyles
              key={`footnote${index}`}
              dangerouslySetInnerHTML={{ __html: footnote }}
            />
          );
        })}
      </div>
    );
  }
  return "";
};

function Label({ label, appConfig }) {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    try {
      const tagManagerArgs = {
        dataLayer: {
          event: "NutritionView",
          view: "Label",
          content_type: "Label",
          content_title: label.identifierAlt || label.identifier,
          content_locale: label.locale,
          voucher: appConfig.voucher,
          client_id: searchParams.get("cid"),
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    } catch (ex) {}
  }, []);

  const onCopy = (e) => {
    e.preventDefault();
  };

  if (label && label.id) {
    return (
      <>
        <TitleStyle>
          {label.groupName
            ? `${label.groupName} (${label.identifier || label.identifierAlt})`
            : label.identifier || label.identifierAlt}
        </TitleStyle>
        <LabelContainerStyles>
          <LabelBlurbStyles>
            <LabelTopLineDesecriptionStyles
              dangerouslySetInnerHTML={{
                __html: label.topline ? label.topline : "TK",
              }}
            />
          </LabelBlurbStyles>
          <div className="scoring">
            <RatingView label={label} />
          </div>
          <div className="label">
            <Writeup label={label} key={"writeup"} />
            <Byline label={label} key={"byline"} />
            <Source sources={label.sources} key={"source"} />
            <Footnote footnotes={label.footnotes} key={"footnote"} />
          </div>
        </LabelContainerStyles>
      </>
    );
  }
  return null;
}

export default Label;
