import React, { Component } from "react";
import { Dialog } from "@reach/dialog";
import VisuallyHidden from "@reach/visually-hidden";
import styled from "@emotion/styled";
import cookie from "react-cookies";
import { FormattedMessage, injectIntl, IntlProvider } from "react-intl";
import messages, {
  getLocale,
} from "../../../../../shared/resources/_localeMessages";
import TagManager from "react-gtm-module";

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  margin-top: 70px;
`;

const Button = styled.button`
  display: block;
  height: 50px;
  color: ${(props) =>
    props.cancel ? "rgba(0,0,0,1)" : "rgba(255, 255, 255, 1)"};
  background-color: ${(props) =>
    props.cancel ? "rgba(0,0,0,0.06)" : "rgba(51, 85, 221, 1)"};
  font-family: Sora;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding: 0 10px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  /* margin: 0 auto 20px; */
  text-transform: uppercase;
  min-width: 150px;
`;

const Link = styled.a`
  color: var(--primary-blue);
  text-decoration: none;
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
`;

const Text = styled.p`
  line-height: 24px;
`;

const COOKIE_NAME = "new_tos_agreed";

class TermsOfService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      cookieNoticeAgreed: false,
    };

    this.buttonRef = React.createRef();

    // check whether cookie notice is agreed upon
    window.setInterval(() => {
      const cookieNoticeAgreed = cookie.load("OptanonAlertBoxClosed");
      if (this.state.cookieNoticeAgreed !== cookieNoticeAgreed) {
        this.setState({ cookieNoticeAgreed: cookieNoticeAgreed });
      }
    }, 100);
  }

  componentDidMount() {
    this.checkDialog();
    cookie.remove("tos_agreed");

    try {
      const tagManagerArgs = {
        dataLayer: {
          event: "NutritionView",
          view: "TOS",
          content_type: "TOS",
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    } catch (e) {}
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps === undefined || prevState === undefined) {
      this.checkDialog();
      return false;
    }

    if (
      prevProps.appConfig.show_dialogs !== this.props.appConfig.show_dialogs
    ) {
      this.checkDialog();
    }

    return true;
  }

  checkDialog = () => {
    let showDialogs = false;
    const tosAgreed = cookie.load(COOKIE_NAME) === "true";
    if (!tosAgreed) {
      cookie.save(COOKIE_NAME, false, {
        path: "/",
      });
    }
    if (!tosAgreed && this.props.appConfig) {
      showDialogs = this.props.appConfig.show_dialogs;
    }
    this.setState({
      openDialog: showDialogs && !tosAgreed,
    });
  };

  handleClose = (e) => {
    const oneWeekInSeconds = 60 * 60 * 24 * 7;
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * oneWeekInSeconds); // 1 week

    cookie.save(COOKIE_NAME, true, {
      path: "/",
      expires,
      maxAge: oneWeekInSeconds,
    });

    this.checkDialog();
  };

  handleNo = () => {
    window.open("https://www.newsguardtech.com", "_blank");
  };

  render() {
    const { intl } = this.props;
    console.log("props", this.props);

    return (
      <IntlProvider locale={getLocale()} messages={messages(getLocale())}>
        {this.state.openDialog && (
          <Dialog onDismiss={this.handleNo} initialFocusRef={this.buttonRef}>
            {/* <button className="close-button" onClick={this.handleNo}>
             <VisuallyHidden>Close</VisuallyHidden>
             <span aria-hidden>×</span>
             </button> */}
            <h1>
              <FormattedMessage
                id={"msg.tosTitle"}
                defaultMessage={"Terms of Service"}
              />
            </h1>
            <Text>
              <FormattedMessage
                id={"msg.tos"}
                defaultMessage={
                  "<p>NewsGuard is for personal use only. Any professional, commercial, research, or other nonpersonal use of NewsGuard is strictly prohibited without a commercial license.</p><p2>By accessing this page, you agree not to use NewsGuard for any prohibited use, including but not limited to use of NewsGuard to curate or target advertisements, to moderate content or sources on digital platforms or news aggregators, to train, enhance, or inform artificial intelligence models, including generative AI models and chatbots, or for research studies, including academic research.</p2><p3>NewsGuard's licencees, library partners, and other partners may use NewsGuard for such purposes if authorized to do so by a commercial or institutional license. <licensing>Request licensing information</licensing>. Journalists reporting on misinformation may request a NewsGuard access code <voucher>here</voucher>.</p3>"
                }
                values={{
                  p: (...chunks) => <p className="strong">{chunks}</p>,
                  p2: (...chunks) => <p>{chunks}</p>,
                  p3: (...chunks) => <p>{chunks}</p>,
                  licensing: (...chunks) => (
                    <Link
                      href={intl.formatMessage({
                        id: "feedbacklink",
                        defaultMessage:
                          "https://www.newsguardtech.com/feedback/give/",
                      })}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {chunks}
                    </Link>
                  ),
                  voucher: (...chunks) => (
                    <Link
                      href="mailto:voucher@newsguardtech.com"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {chunks}
                    </Link>
                  ),
                }}
              />
            </Text>
            <Text>
              <FormattedMessage
                id={"msg.tosTitleLink"}
                defaultMessage={"<a>Terms of Service</a>"}
                values={{
                  a: (...chunks) => (
                    <Link
                      href={intl.formatMessage({
                        id: "msg.tosLink",
                        defaultMessage:
                          "https://www.newsguardtech.com/terms-of-service/",
                      })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunks}
                    </Link>
                  ),
                }}
              />
            </Text>
            <ButtonsWrapper>
              <Button onClick={this.handleNo} cancel>
                <FormattedMessage
                  id={"msg.tosNoThanks"}
                  defaultMessage={"No Thanks"}
                />
              </Button>
              <Button onClick={this.handleClose} ref={this.buttonRef}>
                <FormattedMessage
                  id={"msg.tosIAgree"}
                  defaultMessage={"I Agree"}
                />
              </Button>
            </ButtonsWrapper>
          </Dialog>
        )}
      </IntlProvider>
    );
  }
}

export default injectIntl(TermsOfService);
