/*global chrome*/
import NetworkService from "./NetworkService";

const NutritionService = (() => {
  let instance;

  function createInstance() {
    return new NutritionServiceInstance();
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default NutritionService;

class NutritionServiceInstance {
  store = {};
  domainData = {};

  setStore(store) {
    this.store = store;
  }

  constructor() {}

  requestLabelFromExtension = (host) => {
    window.self.dispatchEvent(new CustomEvent("label_view", { detail: host }));
  };

  labelByToken(token) {
    const that = this;
    let headers = {};

    if (typeof window._csrf != "undefined" && window._csrf) {
      headers[window._csrf.header] = window._csrf.token;
      headers.xsrfHeaderName = window._csrf.header;
    }

    const endpoint = `/labeltoken/${token}`;
    return NetworkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  }

  check(domain) {
    const endpoint = `/check/${encodeURIComponent(domain)}`;
    return NetworkService.get(endpoint).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  }

  getNutritionFromTab = (tab) => {
    let url = new URL(tab.url);
    let domain = url.hostname;

    return this.check(domain);
  };

  getAppConfig = () => {
    const endpoint = `/app_config`;
    return NetworkService.get(endpoint).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };
}
