import React from "react";
import styled from "@emotion/styled";
import { useIntl, FormattedMessage } from "react-intl";

const TOSStyles = styled.p`
  margin-top: 40px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;

  span,
  a {
    font-size: 14px;
    line-height: 20px;
  }
  a {
    text-decoration: none;
    color: rgba(51, 85, 221, 1);
    & :hover {
      text-decoration: underline;
    }
  }
`;

const TOSAndPrivacyPolicy = (props) => {
  const intl = useIntl();
  return (
    <TOSStyles>
      <FormattedMessage
        id="template.tos_privacy_links"
        defaultMessage={
          "<tos>Terms of Service</tos> and <privacy>Privacy Policy</privacy>"
        }
        values={{
          tos: (chunk) => (
            <a
              className="link link--external"
              target="_blank"
              href={intl.formatMessage({
                id: "msg.tosLink",
                defaultMessage:
                  "https://www.newsguardtech.com/terms-of-service/",
              })}
            >
              {chunk}
            </a>
          ),
          privacy: (chunk) => (
            <a
              className="link link--external"
              target="_blank"
              href={intl.formatMessage({
                id: "msg.privateLink",
                defaultMessage: "https://www.newsguardtech.com/privacy-policy/",
              })}
            >
              {chunk}
            </a>
          ),
        }}
      />
    </TOSStyles>
  );
};

export default TOSAndPrivacyPolicy;
